<template>
  <div id="trip-edit">
    <div class="header">
      <van-row gutter="10">
        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-active'"
            :className="'back_icon'"
          />出团编辑
        </van-col>
        <van-col
          span="14"
          class="header-title"
          @click="onUpdateTuanClick"
          style="text-align: right"
        >
          完成
        </van-col>
      </van-row>
    </div>
    <div class="trip-item-body">
      <div class="item-body-title">出团信息</div>
      <van-row class="input-container">
        <van-col span="12">
          <div class="input-text">出团名称</div>
        </van-col>
        <van-col span="12" class="input-item">
          <input
            type="text"
            v-model="tuan.list_name"
            placeholder="请输入出团名称"
          />
        </van-col>
      </van-row>
      <van-row class="input-container" @click="showTravelPlans = true">
        <van-col span="12">
          <div class="input-text">线路</div>
        </van-col>
        <van-col span="12" class="input-item">
          <input
            type="text"
            disabled
            v-model="tuan.travel_plan_name"
            placeholder="请选择路线"
          />
        </van-col>
      </van-row>
      <div class="trip-step" style="margin-top: 20px" v-if="!destroy">
        <van-steps
          direction="vertical"
          active-color="#969799"
          active-icon="star"
          :active="-1"
        >
          <van-step
            v-for="(item, index) in tuan.travel_list_nodes"
            :key="index"
          >
            <h3>
              {{ item.node_name }}
            </h3>
            <p>
              {{ item.node_description }}
            </p>
            <h3 @click="onShowPickerClick(index, 0)" style="margin: 10px 0">
              <input
                type="text"
                style="color: #666; width: 100%"
                disabled
                v-model="item.start_time"
                placeholder="请选择时间"
              />
            </h3>
            <h3 @click="onShowPickerClick(index, 1)">
              <input
                type="text"
                style="color: #666; width: 100%"
                disabled
                v-model="item.end_time"
                placeholder="请选择结束时间"
              />
            </h3>
          </van-step>
        </van-steps>
      </div>
      <div class="trip-step" style="margin-top: 20px" v-if="destroy">
        <van-steps
          direction="vertical"
          active-color="#969799"
          active-icon="star"
          :active="-1"
        >
          <van-step
            v-for="(item, index) in tuan.travel_list_nodes_attributes"
            :key="index"
          >
            <h3>
              {{ item.node_name }}
            </h3>
            <p>
              {{ item.node_description }}
            </p>
            <h3 @click="onShowPickerClick(index, 0)" style="margin: 10px 0">
              <input
                type="text"
                style="color: #666; width: 100%"
                disabled
                v-model="item.start_time"
                placeholder="请选择时间"
              />
            </h3>
            <h3 @click="onShowPickerClick(index, 1)">
              <input
                type="text"
                style="color: #666; width: 100%"
                disabled
                v-model="item.end_time"
                placeholder="请选择结束时间"
              />
            </h3>
          </van-step>
        </van-steps>
        <!-- <div @click="onAddClick">
          <svg-icon :data_iconName="'add'" :className="'add_icon'" />
        </div> -->
      </div>
      <van-row class="input-container" @click="start_date_show = true">
        <van-col span="12">
          <div class="input-text">出发日期</div>
        </van-col>
        <van-col span="12" class="input-item">
          <input
            disabled
            type="text"
            v-model="tuan.start_date"
            placeholder="请选择出发日期"
          />
        </van-col>
      </van-row>
      <van-row class="input-container" @click="end_date_show = true">
        <van-col span="12">
          <div class="input-text">结束日期</div>
        </van-col>
        <van-col span="12" class="input-item">
          <input
            disabled
            type="text"
            v-model="tuan.end_date"
            placeholder="请选择结束日期"
          />
        </van-col>
      </van-row>
      <van-row class="input-container" @click="showGuides = true">
        <van-col span="12">
          <div class="input-text">选择导游</div>
        </van-col>
        <van-col span="12" class="input-item">
          <input
            disabled
            type="text"
            v-model="tuan.guide"
            placeholder="请选择导游"
          />
        </van-col>
      </van-row>
      <van-row class="input-container" @click="showDrivers = true">
        <van-col span="12">
          <div class="input-text">选择司机</div>
        </van-col>
        <van-col span="12" class="input-item">
          <input
            disabled
            type="text"
            v-model="driverName"
            placeholder="请选择司机"
          />
        </van-col>
      </van-row>
      <van-row class="input-container">
        <van-col span="12">
          <div class="input-text">备注</div>
        </van-col>
        <van-col span="12" class="input-item">
          <input type="text" v-model="tuan.note" placeholder="请输入备注" />
        </van-col>
      </van-row>

      <van-popup v-model="showPicker" round position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="datetime"
          title="选择年月日"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="onTimeConfirm"
          @cancel="onTimeCancel"
        />
      </van-popup>
      <!-- <van-row class="input-container">
        <van-col span="12">
          <div class="input-text">线路</div>
        </van-col>
        <van-col span="12" class="input-item">
          <input type="text" v-model="tuan.note" placeholder="请输入备注" />
        </van-col>
      </van-row> -->
      <!-- <van-row>
        <van-col span="24" class="upload-container">
          <van-uploader
            v-if="!tuan.contract_url"
            max-count="1"
            
            accept="image/*"
            :after-read="fnUploadRequest"
          >
            <van-image
              v-if="!tuan.contract_url"
              style="height: 1rem"
              :src="uploadImg"
            />
          </van-uploader>
          <van-image
            @click="contract_url_show = true"
            v-if="tuan.contract_url"
            fit="cover"
            :src="tuan.contract_url"
          />
          <van-popup v-model="contract_url_show" :style="{ width: '90%' }">
            <van-image
              @click="contract_url_show = false"
              :src="tuan.contract_url"
          /></van-popup>
          <div
            v-if="!tuan.contract_url"
            style="color#333;font-size:.2rem;text-align:center;"
          >
            上传出团合同
          </div>
          <div
            v-if="tuan.contract_url"
            style="color#333;font-size:.2rem;text-align:center;color:red"
            @click="tuan.contract_url = ''"
          >
            删除合同
          </div>
        </van-col>
      </van-row> -->
      <van-row>
        <van-col span="24" class="upload-container">
          <div class="item-body-title">出团合同</div>
          <van-uploader
            max-count="1"
            v-model="fileList"
            :before-delete="onDeleteImageUrl"
            preview-full-image
            accept="image/*"
            :after-read="fnUploadRequest"
          />
        </van-col>
      </van-row>

      <van-popup
        v-model="showGuides"
        closeable
        close-icon="close"
        :style="{ height: '40%' }"
        position="bottom"
      >
        <div class="select-container">
          <div class="select-title">选择导游</div>
          <div
            class="select-item"
            v-for="(i, index) in tuan.select_guides"
            :key="index"
            @click="selectGuide(i)"
          >
            {{ i.name }}
          </div>
        </div>
      </van-popup>
      <van-popup
        v-model="showStatusList"
        closeable
        close-icon="close"
        :style="{ height: '30%' }"
        position="bottom"
      >
        <div class="select-container">
          <div class="select-title">选择状态</div>
          <div
            class="select-item"
            v-for="(i, index) in status_list"
            :key="index"
            @click="selectStatus(i)"
          >
            {{ i.text }}
          </div>
        </div>
      </van-popup>
      <van-popup
        v-model="showDrivers"
        closeable
        close-icon="close"
        :style="{ height: '40%' }"
        position="bottom"
      >
        <div class="select-container">
          <div class="select-title">选择司机</div>
          <div
            class="select-item"
            v-for="(i, index) in drivers"
            :key="index"
            @click="selectDriver(i)"
          >
            {{ i.name }}
          </div>
        </div>
      </van-popup>
      <van-popup
        v-model="showTravelPlans"
        closeable
        close-icon="close"
        :style="{ height: '30%' }"
        position="bottom"
        ><div class="select-container">
          <div class="select-title">选择线路</div>
          <div
            class="select-item"
            v-for="(i, index) in travel_plans"
            :key="index"
            @click="selectTravelPlans(i)"
          >
            {{ i.plan_name }}
          </div>
        </div>
      </van-popup>
      <van-calendar v-model="start_date_show" @confirm="startDateonConfirm" />
      <van-calendar v-model="end_date_show" @confirm="endDateonConfirm" />
      <div class="btn" @click="onUpdateTuanClick">完成</div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { Dialog } from "vant";
import oss from "@/utils/oss";
export default {
  name: "trip-edit",
  components: {},
  data() {
    return {
      list: [],
      fileList: [],
      destroy: false,
      status_list: [
        {
          text: "待出发",
          value: 0,
        },
        {
          text: "进行中",
          value: 1,
        },
        {
          text: "已完成",
          value: 2,
        },
        {
          text: "已取消",
          value: 3,
        },
      ],
      current_node_index: 0,
      subindex: 0,
      showPicker: false,
      showTravelPlans: false,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      contract_url_show: false,
      start_date_show: false,
      end_date_show: false,
      showStatusList: false,
      active: 1,
      show: false,
      showGuides: false,
      item: {},
      loading: true,
      finished: false,
      refreshing: false,
      query: {
        page: 1,
        plan_name: "",
      },
      guides: [],
      travel_plans: [],
      plan_name: "",
      tuan: {
        end_date: "",
        guide: "",
        guide_mobile: "",
        list_name: "",
        note: "",
        select_guides: [],
        start_date: "",
        status: "",
        status_text: "",
        // tourists_count: "",
        tourists: [],
        contract_url: "",
        travel_plan: {
          plan_name: "",
        },
        travel_list_nodes: [],
        travel_list_nodes_attributes: [],
      },
      showDrivers: false,
      driverName: "",
      drivers: [],
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onConfirm(e) {
      this.showGuides = false;
    },
    onCancel(e) {
      this.showGuides = false;
    },
    // formatDate(date) {
    //   return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    // },
    getDateTime(date) {
      return new Date(date.replace(/-/g, "/")).getTime();
    },
    formatDate(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },
    startDateonConfirm(date) {
      this.start_date_show = false;
      this.tuan.start_date = this.formatDate(date);
    },
    endDateonConfirm(date) {
      this.end_date_show = false;
      this.tuan.end_date = this.formatDate(date);
    },
    onAddClick() {
      const attributes_length = this.tuan.tuan_nodes_attributes.length;
      const next_sort_id =
        this.tuan.tuan_nodes_attributes[attributes_length - 1].sort_id + 1;

      this.tuan.tuan_nodes_attributes.push({
        sort_id: next_sort_id,
        node_name: "",
        node_description: "",
      });
    },
    selectDriver(item) {
      this.driverName = item.name;
      this.tuan.driver_id = item.id;
      this.showDrivers = false;
    },
    async get_drivers() {
      const res = await this.$apis.get_drivers();
      this.drivers = res.data;
    },
    onItemClick() {
      this.$router.push("/trip/item");
    },
    onEditClick() {
      this.$router.push({ path: "/trip/edit" });
    },
    onShowClick() {
      this.show = true;
    },
    selectStatus(item) {
      this.tuan.status_text = item.text;
      this.tuan.status = item.value;
      this.showStatusList = false;
    },
    selectGuide(item) {
      this.tuan.guide_id = item.id;
      this.tuan.guide = item.name;
      this.showGuides = false;
    },
    onShowPickerClick(index, subindex) {
      this.showPicker = true;
      this.current_node_index = index;
      this.subindex = subindex;
    },
    p(s) {
      return s < 10 ? "0" + s : s;
    },
    onTimeConfirm(e) {
      const d = new Date(e);
      const resDate =
        d.getFullYear() +
        "-" +
        this.p(d.getMonth() + 1) +
        "-" +
        this.p(d.getDate());
      const resTime =
        this.p(d.getHours()) +
        ":" +
        this.p(d.getMinutes()) +
        ":" +
        this.p(d.getSeconds());
      const start_time = resDate + " " + resTime;

      if (this.destroy) {
        if (this.current_node_index != 0) {
          if (
            this.tuan.travel_list_nodes_attributes[this.current_node_index - 1]
              .start_time == ""
          ) {
            Toast("请先添加上个站的时间");
            return false;
          }
          // if (
          //   start_time <
          //   this.tuan.travel_list_nodes_attributes[this.current_node_index - 1]
          //     .start_time
          // ) {
          //   Toast("请选比上个站大的时间");
          //   return false;
          // }
          if (
            this.getDateTime(start_time) <
              this.getDateTime(
                this.tuan.travel_list_nodes_attributes[
                  this.current_node_index - 1
                ].start_time
              ) ||
            this.getDateTime(start_time) <
              this.getDateTime(
                this.tuan.travel_list_nodes_attributes[
                  this.current_node_index - 1
                ].end_time
              )
          ) {
            Toast("请选比上个站大的时间");
            return false;
          }
        }
        if (
          this.current_node_index !=
          this.tuan.travel_list_nodes_attributes.length - 1
        ) {
          // if (
          //   start_time >
          //     this.tuan.travel_list_nodes_attributes[
          //       this.current_node_index + 1
          //     ].start_time &&
          //   this.tuan.travel_list_nodes_attributes[this.current_node_index + 1]
          //     .start_time != ""
          // ) {
          //   Toast("请选比下个站小的时间");
          //   return false;
          // }
          if (
            (this.getDateTime(start_time) >
              this.getDateTime(
                this.tuan.travel_list_nodes_attributes[
                  this.current_node_index + 1
                ].start_time
              ) &&
              this.tuan.travel_list_nodes_attributes[
                this.current_node_index + 1
              ].start_time != "") ||
            (this.getDateTime(start_time) >
              this.getDateTime(
                this.tuan.travel_list_nodes_attributes[
                  this.current_node_index + 1
                ].end_time
              ) &&
              this.tuan.travel_list_nodes_attributes[
                this.current_node_index + 1
              ].end_time != "")
          ) {
            Toast("请选比下个站小的时间");
            return false;
          }
        }

        // this.tuan.travel_list_nodes_attributes[
        //   this.current_node_index
        // ].start_time = start_time;
        if (this.subindex == 0) {
          if (
            this.getDateTime(start_time) >=
            this.getDateTime(
              this.tuan.travel_list_nodes_attributes[this.current_node_index]
                .end_time
            )
          ) {
            Toast("开始时间必须小于结束时间");
            return false;
          }
          this.tuan.travel_list_nodes_attributes[
            this.current_node_index
          ].start_time = start_time;
        }
        if (this.subindex == 1) {
          if (
            this.tuan.travel_list_nodes_attributes[this.current_node_index]
              .start_time == ""
          ) {
            Toast("请先添加开始时间");
            return false;
          }
          if (
            this.getDateTime(start_time) <=
            this.getDateTime(
              this.tuan.travel_list_nodes_attributes[this.current_node_index]
                .start_time
            )
          ) {
            Toast("开始时间必须小于结束时间");
            return false;
          }

          this.tuan.travel_list_nodes_attributes[
            this.current_node_index
          ].end_time = start_time;
        }

        /////////////////
      } else {
        // if (this.current_node_index != 0) {
        //   if (
        //     !this.tuan.travel_list_nodes[this.current_node_index - 1].start_time
        //   ) {
        //     Toast("请先添加上个站的时间");
        //     return false;
        //   }
        //   if (
        //     start_time <
        //     this.tuan.travel_list_nodes[this.current_node_index - 1].start_time
        //   ) {
        //     Toast("请选比上个站大的时间");
        //     return false;
        //   }
        // }
        // if (this.current_node_index != this.tuan.travel_list_nodes.length - 1) {
        //   if (
        //     start_time >
        //       this.tuan.travel_list_nodes[this.current_node_index + 1]
        //         .start_time &&
        //     this.tuan.travel_list_nodes[this.current_node_index + 1]
        //       .start_time != null
        //   ) {
        //     Toast("请选比下个站小的时间");
        //     return false;
        //   }
        // }
        // this.tuan.travel_list_nodes[this.current_node_index].start_time =
        //   start_time;
        if (this.current_node_index != 0) {
          if (
            this.tuan.travel_list_nodes[this.current_node_index - 1]
              .start_time == ""
          ) {
            Toast("请先添加上个站的时间");
            return false;
          }
          // if (
          //   start_time <
          //   this.tuan.travel_list_nodes[this.current_node_index - 1]
          //     .start_time
          // ) {
          //   Toast("请选比上个站大的时间");
          //   return false;
          // }
          if (
            this.getDateTime(start_time) <
              this.getDateTime(
                this.tuan.travel_list_nodes[this.current_node_index - 1]
                  .start_time
              ) ||
            this.getDateTime(start_time) <
              this.getDateTime(
                this.tuan.travel_list_nodes[this.current_node_index - 1]
                  .end_time
              )
          ) {
            Toast("请选比上个站大的时间");
            return false;
          }
        }
        if (this.current_node_index != this.tuan.travel_list_nodes.length - 1) {
          // if (
          //   start_time >
          //     this.tuan.travel_list_nodes[
          //       this.current_node_index + 1
          //     ].start_time &&
          //   this.tuan.travel_list_nodes[this.current_node_index + 1]
          //     .start_time != ""
          // ) {
          //   Toast("请选比下个站小的时间");
          //   return false;
          // }
          if (
            (this.getDateTime(start_time) >
              this.getDateTime(
                this.tuan.travel_list_nodes[this.current_node_index + 1]
                  .start_time
              ) &&
              this.tuan.travel_list_nodes[this.current_node_index + 1]
                .start_time != "") ||
            (this.getDateTime(start_time) >
              this.getDateTime(
                this.tuan.travel_list_nodes[this.current_node_index + 1]
                  .end_time
              ) &&
              this.tuan.travel_list_nodes[this.current_node_index + 1]
                .end_time != "")
          ) {
            Toast("请选比下个站小的时间");
            return false;
          }
        }

        // this.tuan.travel_list_nodes[
        //   this.current_node_index
        // ].start_time = start_time;
        if (this.subindex == 0) {
          if (
            this.getDateTime(start_time) >=
            this.getDateTime(
              this.tuan.travel_list_nodes[this.current_node_index].end_time
            )
          ) {
            Toast("开始时间必须小于结束时间");
            return false;
          }
          this.tuan.travel_list_nodes[this.current_node_index].start_time =
            start_time;
        }
        if (this.subindex == 1) {
          if (
            this.tuan.travel_list_nodes[this.current_node_index].start_time ==
            ""
          ) {
            Toast("请先添加开始时间");
            return false;
          }
          if (
            this.getDateTime(start_time) <=
            this.getDateTime(
              this.tuan.travel_list_nodes[this.current_node_index].start_time
            )
          ) {
            Toast("开始时间必须小于结束时间");
            return false;
          }

          this.tuan.travel_list_nodes[this.current_node_index].end_time =
            start_time;
        }
      }

      this.showPicker = false;
    },
    onTimeCancel(e) {
      this.showPicker = false;
    },
    async onUpdateTuanClick() {
      // console.log("----", this.tuan);
      // return;
      if (!this.tuan.list_name) {
        Toast("请填写团名");
        return false;
      }
      if (!this.tuan.start_date) {
        Toast("请填写出发日期");
        return false;
      }

      if (!this.tuan.end_date) {
        Toast("请填写结束日期");
        return false;
      }
      if (
        new Date(this.tuan.start_date.replace(/-/g, "/")).getTime() >
        new Date(this.tuan.end_date.replace(/-/g, "/")).getTime()
      ) {
        Toast("结束日期必须大于出发日期");
        return false;
      }
      if (!this.tuan.guide_id) {
        Toast("请选择导游");
        return false;
      }
      if (!this.tuan.travel_plan_id) {
        Toast("请选择线路");
        return false;
      }
      var flag = true;
      var time_flag = true;
      if (this.destroy) {
        this.tuan.travel_list_nodes_attributes.find((value) => {
          if (value.start_time == "") {
            flag = false;
            Toast("请给行程添加时间");
            return false;
          }
          if (
            new Date(value.start_time.replace(/-/g, "/")).getTime() >
            new Date(this.tuan.end_date.replace(/-/g, "/")).getTime() + 86400000
          ) {
            time_flag = false;
            Toast("行程时间需在出发日期和结束日期范围内");
            return false;
          }
          if (
            new Date(value.start_time.replace(/-/g, "/")).getTime() <
            new Date(this.tuan.start_date.replace(/-/g, "/")).getTime()
          ) {
            time_flag = false;
            Toast("行程时间需在出发日期和结束日期范围内");
            return false;
          }
        });
      }

      if (!this.destroy) {
        var flag = true;
        this.tuan.travel_list_nodes.find((value) => {
          if (!value.start_time) {
            flag = false;
            Toast("请给行程添加时间");
            return false;
          }
          if (
            new Date(value.start_time.replace(/-/g, "/")).getTime() >
            new Date(this.tuan.end_date.replace(/-/g, "/")).getTime() + 86400000
          ) {
            time_flag = false;
            Toast("行程时间需在出发日期和结束日期范围内");
            return false;
          }
          if (
            new Date(value.start_time.replace(/-/g, "/")).getTime() <
            new Date(this.tuan.start_date.replace(/-/g, "/")).getTime()
          ) {
            time_flag = false;
            Toast("行程时间需在出发日期和结束日期范围内");
            return false;
          }
        });
      }
      if (!flag) {
        Toast("请给行程添加时间");
        return false;
      }
      if (!time_flag) {
        Toast("行程时间需在出发日期和结束日期范围内");
        return false;
      }

      const _self = this;
      const id = this.$route.query.id;
      var data = {
        travel_list: {
          list_name: this.tuan.list_name,
          start_date: this.tuan.start_date,
          end_date: this.tuan.end_date,
          guide_id: this.tuan.guide_id ? this.tuan.guide_id : null,
          tourists_count: this.tuan.tourists_count,
          note: this.tuan.note,
          status: this.tuan.status,
          contract_url: this.tuan.contract_url,
          travel_list_nodes_attributes: this.destroy
            ? this.tuan.travel_list_nodes.concat(
                _self.tuan.travel_list_nodes_attributes
              )
            : this.tuan.travel_list_nodes,
          travel_plan_id: this.tuan.travel_plan_id,
          driver_id: this.tuan.driver_id,
        },
      };
      //   } else {
      //     var data = {
      //       travel_list: {
      //         list_name: this.tuan.list_name,
      //         start_date: this.tuan.start_date,
      //         end_date: this.tuan.end_date,
      //         tourists_count: this.tuan.tourists_count,
      //         note: this.tuan.note,
      //         contract_url: this.tuan.contract_url,
      //         status: this.tuan.status,
      //         travel_list_nodes_attributes:this.tuan.travel_list_nodes
      //       },
      //     };
      //   }

      // return false
      const res = await this.$apis.put_travel_list(id, data);
      if (res.status == 200) {
        Toast("修改成功！");
        this.$router.go(-1);
      }
    },
    selectTravelPlans(item) {
      this.tuan.travel_plan_id = item.id;
      this.tuan.travel_plan_name = item.plan_name;
      this.showTravelPlans = false;
      this.get_plan_nodes(item.id);
    },
    async get_plan_nodes(id) {
      const res = await this.$apis.get_plan_nodes(id);
      if (res.status == 200) {
        res.nodes.forEach(function (item) {
          item.start_time = "";
          item.end_time = "";
        });
        this.destroy = true;
        this.tuan.travel_list_nodes_attributes = res.nodes;
        this.tuan.travel_list_nodes.forEach((item) => {
          item._destroy = true;
        });
        this.$forceUpdate();
      }
    },
    async get_travel_list(id) {
      const res = await this.$apis.get_travel_list(id);
      this.tuan = res.data;
      this.driverName = res.data.driver_name;
      const guides = [];
      res.data.travel_list_nodes.forEach((item) => {
        delete item.stay_time;
        delete item.is_checkin;
      });
      res.data.select_guides.forEach((element) => {
        guides.push(element.name);
      });
      this.plan_name = res.data.travel_plan.plan_name;
      this.guides = guides;
    },
    //获取可选线路
    async get_select_travel_plans() {
      const res = await this.$apis.get_select_travel_plans();
      this.travel_plans = res.data;
    },
    onDeleteImageUrl() {
      this.tuan.contract_url = "";
      this.fileList = [];
    },
    fnUploadRequest(option) {
      option.status = "uploading";
      option.message = "上传中...";
      const _self = this;
      oss.ossUploadFile(option).then((res) => {
        _self.tuan.contract_url = res.fileUrl[0].split("?")[0];
        option.status = "success";
        // option.message = '上传中...';
        _self.fileList = [
          {
            url: res.fileUrl[0].split("?")[0],
          },
        ];
        Toast.clear();
      });
    },
  },
  created() {
    const id = this.$route.query.id ? this.$route.query.id : "1";
    this.get_travel_list(id);
    this.get_select_travel_plans();
    this.get_drivers();
  },
};
</script>

<style scoped>
#trip-edit {
  background: #f3f3f3;
  height: 100vh;
  overflow-y: hidden;
  height: 100vh;
  padding-top: 10vh;
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #333;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.search {
  position: relative;
}
.search-input {
  border-radius: 14.5px;
  border-radius: 14.5px;
  height: 0.6rem;
  width: 100%;
  background: rgba(216, 216, 216, 0.21);
  padding-right: 1rem;
  padding-left: 0.3rem;
  font-size: 0.4rem;
  color: #fff;
  vertical-align: middle;
  line-height: 0.6rem;
}
.search-input-icon {
  position: absolute;
  right: 0.2rem;
  top: 50%;
  transform: translateY(-0.1rem);
  font-size: 0.4rem;
}
.trip-item-body {
  height: 85vh;
  width: 90%;
  margin: 0 auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: 2vh;
  padding: 0.4rem 0.4rem;
  overflow-y: scroll;
  padding-bottom: 6rem;
  position: relative;
  background-color: #fff;
}
.item-body-title {
  font-size: 0.3rem;
  color: #333333;
  font-weight: bold;
  height: 0.8rem;
  line-height: 0.8rem;
}
.input-container {
  background: #eaeaea;
  border-radius: 24px;
  border-radius: 24px;
  height: 0.8rem;
  line-height: 0.8rem;
  padding: 0 0.4rem;
  margin-top: 0.3rem;
}
.input-text {
  text-align: left;
  font-size: 12px;
  color: #333333;
}
.input-item > input {
  background: #eaeaea;
  width: 70%;
  height: 0.8rem;
  line-height: 0.8rem;
  color: #333;
  font-size: 12px;
  float: right;
  text-align: left;
}
.input-item > input::placeholder {
  font-size: 12px;
  color: #b1b1b1;
  text-align: left;
}
.xianlu-container {
  margin-top: 0.4rem;
}
.trip-step {
  /* margin-top: 0.4rem;border-top:1px dashed #ebedf0;
    padding-top: .5rem; */
}
.add_icon {
  font-size: 0.5rem;
}
.trip-step input::placeholder {
  color: #999;
}
.select-container {
  height: 100%;
  /* padding-top:1rem; */
  background: #fff;
}
.select-item {
  font-size: 14px;
  color: #333333;
  font-weight: bold;
  text-align: center;
  height: 0.8rem;
  line-height: 0.8rem;
}
.select-item:nth-child(even) {
  background: #f4f3f3;
}
.select-item:nth-child(odd) {
  background: #fff;
}
.select-title {
  font-size: 0.3rem;
  text-align: center;
  font-weight: bold;
  height: 0.8rem;
  line-height: 0.8rem;
}
.upload-container {
  margin-top: 20px;
  border-radius: 6px;
  text-align: left;
}
.upload-container img {
  /* margin-top:20px; */
  border-radius: 6px;
}
.input-item input:not(:placeholder-shown) {
  opacity: 1;
  -webkit-text-fill-color: #333;
}
.van-popup {
  background-color: rgba(216, 216, 216, 0) !important;
}
.btn {
  height: 0.8rem;
  text-align: center;
  color: #436ef3;
  background: #ecf0fe;
  line-height: 0.8rem;
  margin-top: 0.4rem;
  font-size: 0.3rem;
  border-radius: 5px;
}
</style>
